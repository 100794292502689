import React from "react";
import Nav from "./Navbar";
import Hero from "./Hero";
import Title from "./Title";
import Footer from "../Common/Footer/FooterN";
function Contactus() {
  return (
    <>
      <Nav />
      <Title />
      <Hero />
      <Footer/>
    </>
  );
}

export default Contactus;
