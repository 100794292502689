const faqData = {
    ahmedabad: [
        {
            question:
                "What are the popular spa treatments offered in Ahmedabad?",
            answer: (
                <>
                    Ahmedabad's spas offer a variety of popular treatments, including traditional massages like Swedish, deep tissue, and Thai massages. Facials using natural ingredients, body scrubs, and wraps are also commonly sought after.
                    <br />
                    Additionally, many spas in Ahmedabad provide specialized therapies such as aromatherapy, hot stone massages, and reflexology.

                </>
            ),
        },
        {
            question: "How can I find the best spa in Ahmedabad for my specific needs?            ",
            answer: (
                <>
                    You can start by researching online reviews, asking for recommendations from friends or family, and checking out the social media pages of spas in Ahmedabad. Apart from these, there’s a quick and simple thing that you can do is to visit <div style={{ fontStyle: "italic" }}>spa.trakky.in</div>, Trakky helps you to review all the best spas in Ahmedabad and book them effortlessly.
                    <br />    Consider your specific requirements, such as spa or wellness services, to narrow down your options.
                </>
            ),
        },
        {
            question: "Are advance reservations necessary for spa appointments in Ahmedabad?",
            answer: (
                <>
                    While some spas in Ahmedabad may accommodate walk-ins, it's generally recommended to make advance reservations, especially during peak hours or weekends, to secure your preferred time slot and ensure availability.
                    <br />
                    You can make your advance reservation through Trakky to get a seamless spa experience.
                </>
            ),
        },
        {
            question:
                "What types of spas are available in Ahmedabad?",
            answer: (
                <>
                    In Ahmedabad, you can find a diverse range of spas catering to various preferences.
                    <br />
                    Wellness spas focus on holistic well-being and often offer services like yoga, meditation, and nutritional counseling. Beauty spas specialize in skincare and beauty treatments, including facials, manicures, and pedicures. Ayurvedic spas provide traditional Ayurvedic therapies, while luxury spas offer a premium experience with exclusive treatments and high-end amenities.
                </>
            ),
        },
        {
            question: "What is the average cost of spa treatments in Ahmedabad?",
            answer: (
                <>
                    The cost of spa treatments in Ahmedabad can vary based on the type of treatment and the spa's location and reputation.
                    <br />
                    On average, basic treatments may start from a few hundred rupees, while more specialized or luxury treatments can cost significantly more. It's advisable to check with individual spas for their specific pricing on <strong style={{ fontStyle: "italic" }}>Trakky</strong>.
                </>
            ),
        },
    ],
    gandhinagar: [
        {
            question: "How can I stay updated on the latest offers and promotions from spas in Gandhinagar?",
            answer: (
                <>
                    To stay updated on the latest offers and promotions from spas in Gandhinagar, there are several things you can do:
                    <br />
                    <ul style={{ paddingLeft: '3rem' }}>
                        <li style={{ listStyleType: 'disc' }}>Check spa.trakky.in</li>
                        <li style={{ listStyleType: 'disc' }}>Follow spa social media accounts</li>
                        <li style={{ listStyleType: 'disc' }}>Subscribe to spa newsletters</li>
                        <li style={{ listStyleType: 'disc' }}>Check spa websites for promotions</li>
                    </ul>
                </>
            ),
        },
        {
            question:
                "Are walk-ins accepted at spas in Gandhinagar, or is it advisable to make appointments?",
            answer: (
                <>
                    While some spas in Gandhinagar may accept walk-ins, it's generally advisable to make appointments, especially during peak hours, to ensure timely service.
                    <br />
                    You won’t like being in a waiting queue for hours, would you? That’s where <strong>Trakky</strong>  comes to your salvation. Trakky helps you book spa appointments and avoid unnecessary waiting time in spa queues.
                </>
            ),
        },
        {
            question:
                "Are there any specialized wellness programs available at spas in Gandhinagar?",
            answer: (
                <>
                    Some spas in Gandhinagar offer specialized wellness programs focused on specific health goals. These may include weight management programs, detoxification packages, or stress-relief programs incorporating a variety of treatments and therapies.
                </>
            ),
        },
        {
            question: "Do spas in Gandhinagar offer group or couple packages?",
            answer: (
                <>
                    Yes, many spas in Gandhinagar provide group and couple packages. These packages often include side-by-side treatments in a private room, creating a shared and enjoyable spa experience for friends or couples.
                </>
            ),
        },
        {
            question: "What types of spa treatments are commonly available in Gandhinagar?",
            answer: (
                <>
                    In Gandhinagar, you can find a diverse array of spa treatments. These include traditional massages like Swedish and deep tissue, rejuvenating facials, body scrubs, and holistic wellness therapies such as aromatherapy and Ayurvedic treatments.
                </>
            ),
        },
    ],
    bangalore: [
        {
            question:
                "What is the recommended attire for spa treatments in Bangalore?",
            answer: (
                <>
                    Spas usually provide disposable undergarments for clients during treatments. However, clients can bring their own swimwear or undergarments if they prefer. It's recommended to remove any jewelry before the treatment to ensure a comfortable and uninterrupted spa experience. </>
            ),
        },
        {
            question:
                "What is the average cost of spa treatments in Bangalore?",
            answer: (
                <>
                    The cost of spa treatments in Bangalore varies based on factors such as the type of service, duration, and the spa's location and reputation.
                    <br />
                    On average, basic treatments like a one-hour massage can range from Rs. 1000 to Rs. 3000, while premium services may be priced higher. Specialized or package treatments can also influence the overall cost.
                </>
            ),
        },
        {
            question:
                "How can I find the best spa in Bangalore for my needs?",
            answer: (
                <>
                    To find the best spa in Bangalore, consider your specific requirements. Read online reviews, ask for recommendations from friends or locals, and check social media for insights. Additionally, visiting spa websites and understanding their services can help you make an informed decision.
                    <br />
                    Apart from these, there’s a quick and simple thing that you can do is to visit spa.trakky.in, Trakky helps you to review all the best spas in Bangalore and book them without any hassle.
                </>
            ),
        },
        {
            question:
                "Are there any age restrictions for spa treatments in Bangalore?",
            answer: (
                <>
                    While many spas in Bangalore cater to adult clients, some may have specific age restrictions for certain treatments. It's advisable to check with the spa beforehand if you plan to bring a minor for any particular service, ensuring a smooth and appropriate spa experience.
                </>
            ),
        },
        {
            question: "What languages are commonly spoken at salons in Bangalore?",
            answer: (
                <>
                    English, Kannada, and Hindi are commonly spoken languages at salons in
                    Bangalore. However, many salons have staff proficient in multiple
                    languages to cater to a diverse clientele.
                </>
            ),
        },
        {
            question: "Can I purchase spa gift vouchers in Bangalore?",
            answer: (
                <>
                    Yes, many spas in Bangalore offer gift vouchers that can be purchased for personal use or as thoughtful gifts. These vouchers often come in various denominations and can be redeemed for a variety of spa services, allowing recipients to choose the treatment that suits their preferences.
                </>
            ),
        },
    ],
    default: [
        {
            question: "What happens in a spa in India?",
            answer: (
                <div>
                    <p>In India, spa experiences can vary greatly depending on the location, the type of spa, and the specific services offered. However, here are some common experiences you might find in a spa in India:</p>
                    <br />
                    <ul>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Ayurvedic Treatments:</strong> Many spas in India offer traditional Ayurvedic treatments, which are based on ancient Indian healing practices. These treatments often involve herbal oils, massages, and other therapies aimed at balancing the body's energies.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Massages:</strong> Indian spas are known for their massages, which can range from relaxing to therapeutic. Popular massage techniques include Ayurvedic massage, Swedish massage, deep tissue massage, and hot stone massage.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Yoga and Meditation:</strong> Some spas in India offer yoga and meditation sessions as part of their wellness packages. These sessions may be conducted by experienced instructors and can help guests relax, improve flexibility, and reduce stress.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Steam Rooms and Saunas:</strong> Many Indian spas have steam rooms and saunas where guests can relax and detoxify their bodies. These facilities are often complemented by cold plunge pools or showers for contrast therapy.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Beauty Treatments:</strong> Indian spas often offer a range of beauty treatments such as facials, body scrubs, and manicures/pedicures using natural ingredients like herbs, spices, and fruits.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Specialized Therapies:</strong> Depending on the spa, you may find specialized therapies such as hydrotherapy, reflexology, aromatherapy, and even sound therapy.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Nutrition and Wellness Counseling:</strong> Some spas offer consultations with nutritionists or wellness experts who provide advice on healthy eating, lifestyle changes, and holistic wellness.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Cultural Elements:</strong> In some spas, especially those located in heritage properties or resorts, you may find cultural elements incorporated into the experience, such as traditional music, dance performances, or rituals.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Detox Programs:</strong> Some spas in India offer detox programs that include cleansing diets, herbal supplements, and treatments aimed at eliminating toxins from the body.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Customized Packages:</strong> Many spas offer customized packages tailored to individual needs, combining various treatments and therapies to address specific health or wellness goals.
                        </li>
                    </ul>
                    <br />
                    Overall, a spa experience in India is often designed to promote relaxation, rejuvenation, and holistic well-being, drawing from traditional Indian healing practices and modern wellness techniques.
                </div>
            ),
        },
        {
            question:
                "Which massage is best in India?",
            answer: (
                <>
                    <p>
                        The best massage in India can be subjective and depends on personal preferences, health needs, and the desired outcome. However, some of the popular and highly regarded massages in India include:
                    </p>
                    <br />
                    <ul>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Ayurvedic Massage:</strong> Based on Ayurveda, an ancient Indian system of medicine, Ayurvedic massages use herbal oils and specific techniques to balance the body's energies (doshas). Abhyanga is a common Ayurvedic full-body massage that aims to promote relaxation, improve circulation, and detoxify the body.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Swedish Massage:</strong> Known for its gentle and relaxing strokes, Swedish massage is widely offered in spas across India. It focuses on promoting overall relaxation, easing tension, and improving blood circulation.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Deep Tissue Massage:</strong> Ideal for individuals with muscle tension and chronic pain, deep tissue massage involves applying firm pressure to reach deeper layers of muscles and connective tissue. It can help release knots and alleviate stiffness.                </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Hot Stone Massage:</strong> This massage involves placing heated stones on specific points of the body. The warmth from the stones helps relax muscles, easing tension and promoting a sense of well-being.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Thai Massage:</strong> Originating from Thailand, Thai massage combines acupressure, yoga-like stretches, and massage. It aims to improve flexibility, increase energy flow, and release tension. Many spas in India offer traditional Thai massages.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Shirodhara:</strong> While not a traditional massage, Shirodhara is a unique Ayurvedic therapy where warm herbal oil is poured in a continuous stream onto the forehead. It is known for inducing deep relaxation and calming the nervous system.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Balinese Massage:</strong> Inspired by traditional Indonesian techniques, Balinese massage combines acupressure, reflexology, and gentle stretching. It aims to improve blood circulation, relieve muscle tension, and promote relaxation.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Foot Reflexology:</strong> This massage focuses on pressure points on the feet that correspond to various organs and systems in the body. Foot reflexology is believed to promote overall well-being and balance.
                        </li>
                    </ul>
                </>
            ),
        },
        {
            question: "What do you wear to a full-body massage?",
            answer: (
                <p>
                    One common question that often crosses the minds of spa-goers is, "What should I wear?" The answer is comfort. Most spas provide comfortable robes and slippers for you to change into before your massage.
                    <br />
                    However, it's advisable to wear something easy to slip out of, as you'll want to be in minimal clothing during the massage. Remember, the focus is on your comfort, so wear whatever allows you to relax and enjoy the experience.

                </p>
            ),
        },
        {
            question: "Why do people need online booking for spas?",
            answer: (
                <div>
                    <p>
                        Online booking for spas offers several advantages for both spa businesses and their customers, contributing to a more convenient and efficient experience. Here are some reasons why people often prefer online booking for spa appointments:
                    </p>
                    <br />
                    <ul>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Convenience:</strong> Online booking provides the convenience of scheduling spa appointments at any time, 24/7, from the comfort of one's home or on the go. This eliminates the need for making phone calls during business hours and allows individuals to book at their convenience.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Accessibility:</strong> Online booking platforms are accessible from various devices, including smartphones, tablets, and computers. This accessibility allows customers to quickly check availability, browse services, and make reservations from virtually anywhere.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Real-time Availability:</strong> Online spa booking systems often provide real-time updates on appointment availability. Customers can see the current schedule, choose from available time slots, and receive immediate confirmation, reducing the likelihood of scheduling conflicts.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Streamlined Process:</strong> The online spa booking process is typically straightforward. Users can easily navigate through available services, select preferences, and complete the booking process with just a few clicks. This streamlined process saves time for both customers and spa staff.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Service Information and Reviews:</strong> Online spa booking platforms often include detailed information about the spa's services, pricing, and customer reviews. This helps customers make informed decisions about which services to choose and which spa to visit.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Automated Reminders:</strong> Many online spa booking systems send automated reminders to customers, reducing the likelihood of missed appointments. These reminders can be in the form of emails or text messages, helping clients stay organized and ensuring a higher attendance rate.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Flexible Cancellation and Rescheduling:</strong> Online spa booking platforms often allow users to easily cancel or reschedule appointments within a specified time frame. This flexibility is appreciated by customers who may need to adjust their plans due to unforeseen circumstances.
                        </li>
                        <li style={{ listStyleType: 'disc' }}>
                            <strong style={{ textDecoration: 'underline' }}>Integration with Other Services:</strong> Some online booking systems integrate with other services, such as payment processing or loyalty programs, providing a seamless and comprehensive experience for both businesses and clients.
                        </li>
                    </ul>
                    <br />
                    <p>
                        Overall, online booking for spas enhances the customer experience by offering flexibility, accessibility, and convenience while streamlining operations for spa businesses.
                    </p>
                </div>
            ),
        },
        {
            question: "What is the best online platform to book my spa appointment?",
            answer: (
                <>
                    <p>
                        When it comes to booking your spa appointments seamlessly and experiencing the epitome of convenience,<strong style={{ fontStyle: 'italic' }}>
                            Trakky takes the crown!</strong>  Trakky isn't just a booking platform; it's your personalized gateway to the best spas in India.
                    </p>
                    <br />
                    <p>
                        Our user-friendly platform ensures a seamless experience, giving you the power to schedule your spa sessions with ease. With Trakky, you're not limited by location. Our platform brings famous spa brands to you in popular cities across India – Ahmedabad, Bangalore, and Gandhinagar.
                    </p>
                    <br />
                    <p>
                        If that’s not enough! Trakky goes beyond just booking – we bring you exclusive offers and exciting deals from the finest spas. And, we continuously strive to bring you innovative solutions, ensuring you stay ahead of the curve when it comes to the latest trends and techniques in the world of spa and wellness.
                    </p>
                </>
            ),
        },
    ],
};

export default faqData;